import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import * as headertopStyles from "./headertop.module.css"
import { Icon } from '@iconify/react';
import bxsPhone from '@iconify/icons-bx/bxs-phone';
import lineIcon from '@iconify/icons-cib/line';
import facebookIcon from '@iconify/icons-brandico/facebook';
import instagramFill from '@iconify/icons-akar-icons/instagram-fill';
import bxlYoutube from '@iconify/icons-bx/bxl-youtube';
import faxIcon from '@iconify/icons-cil/fax';

export default function Headertop () {
    return (
        <div className={headertopStyles.headerbg}>
        <Container fluid="xl">
            <Row className={headertopStyles.fontheadertop}>
                <Col md={6} xs={12}><Icon icon={bxsPhone} height={18} style={{marginRight: '5px'}} /> 034-471-614 <Icon icon={faxIcon} height={18} style={{marginLeft: '10px', marginRight: '5px'}} /> 034-471-615</Col>
                <Col className={headertopStyles.headertop} md={3} xs={12}>
                </Col>
                <Col className={headertopStyles.headertop} md={3} xs={12}>
                <ul className={headertopStyles.bulletsno}>
                    <li className={headertopStyles.lipaddingsocial}><Icon icon={facebookIcon} height={15} /></li>
                    <li className={headertopStyles.lipaddingsocial}><Icon icon={instagramFill} height={15} /></li>
                    <li className={headertopStyles.lipaddingsocial}><Icon icon={bxlYoutube} height={23} /></li>
                    <li className={headertopStyles.lipaddingsocial}><Icon icon={lineIcon} height={16} /> @Thaipolypack</li>
                </ul>
                </Col>
            </Row>
        </Container>
        </div>
    );
}
