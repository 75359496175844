import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Containers from "../components/container"
import * as footerStyles from "./footer.module.css"
import { Icon } from '@iconify/react';
import bxsPhone from '@iconify/icons-bx/bxs-phone';
import faxIcon from '@iconify/icons-cil/fax';
import { StaticImage } from "gatsby-plugin-image"

export default function Footer () {
    return (
        <Containers>
            <div className={footerStyles.footerbackground}>
            <Container fluid="xl">
                <Row>
                    <Col md={3}></Col>
                    <Col md={4} className={footerStyles.colimg}><StaticImage src="../images/Logofooter.png" alt="Thaipolypackfooter" className={footerStyles.footerbrand} /></Col>
                    <Col md={2} className={footerStyles.colcenter}>
                        <ul className={footerStyles.bulletsphone}>
                            <li><Icon icon={bxsPhone} height={20} style={{marginBottom: '3px'}} /> 034-471-614</li>
                            <li><Icon icon={faxIcon} height={20} style={{margin: '3px 0px', marginBottom: '15px'}} /> 034-471-615</li>
                        </ul>
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Row>
                    <Col><center><p className={footerStyles.plocation}>99/65 หมู่ที่ 2 ตำบลท่าเสา อำเภอกระทุ่มแบน จ.สมุทรสาคร 74110</p></center></Col>
                </Row>
                <Row>
                    <Col className={footerStyles.colfootb}><center><p className={footerStyles.fontfooterb}>Thaipolypack – Factory, Industrial</p></center></Col>
                </Row>
                <Row>
                    <Col><center><p className={footerStyles.fontfooterc}>Thaipolypack – Gatsbyjs 2021 by Onigitop.co.ltd</p></center></Col>
                </Row>
            </Container>
            </div>
        </Containers>
    )
}