// extracted by mini-css-extract-plugin
export var bulletsno = "footer-module--bulletsno---D-bh";
export var bulletsnoa = "footer-module--bulletsnoa--1paGd";
export var bulletsphone = "footer-module--bulletsphone--waq3q";
export var lipadding = "footer-module--lipadding--3pZ0f";
export var lipaddinga = "footer-module--lipaddinga--1bHa3";
export var lipaddingsocial = "footer-module--lipaddingsocial--2-zn6";
export var fontp = "footer-module--fontp--3vSeK";
export var footerbackground = "footer-module--footerbackground--shag7";
export var colcenter = "footer-module--colcenter--_zP7n";
export var colimg = "footer-module--colimg--Ewhia";
export var plocation = "footer-module--plocation--2ZBrX";
export var fform = "footer-module--fform--2m2OL";
export var fbuttom = "footer-module--fbuttom--2WDbn";
export var fp = "footer-module--fp--2ii5V";
export var fontfooter = "footer-module--fontfooter--qg4qy";
export var fontfooterb = "footer-module--fontfooterb--o-fIP";
export var fontfooterc = "footer-module--fontfooterc--3YOMq";
export var colfootb = "footer-module--colfootb--2nyyP";
export var bulletsmenu = "footer-module--bulletsmenu--2XNF4";
export var bulletsmenua = "footer-module--bulletsmenua--hjI3k";
export var linkfooter = "footer-module--linkfooter--2NEVo";
export var footerbrand = "footer-module--footerbrand--1SHha";