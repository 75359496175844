import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import * as footerStyles from "./footer.module.css"
import { Icon } from '@iconify/react';
import lineIcon from '@iconify/icons-cib/line';
import facebookIcon from '@iconify/icons-brandico/facebook';
import instagramFill from '@iconify/icons-akar-icons/instagram-fill';
import bxlYoutube from '@iconify/icons-bx/bxl-youtube';

export default function Formfooter () {
    return (
        <div className={footerStyles.fontp}>
        <Container fluid="xl">
            <Row>
                <Col md={3} xs={12}><p className={footerStyles.fp}><b>สนใจฝากชื่อเบอร์โทรให้เราติดต่อกลับ</b></p></Col>
                <Col md={6} xs={12}>
                <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                    <ul className={footerStyles.bulletsno}>
                        <li className={footerStyles.lipaddingsocial}><input className={footerStyles.fform} type="text" placeholder="เบอร์โทรศัพท์" name="เบอร์โทรศัพท์" /></li>
                        <li className={footerStyles.lipaddingsocial}><input className={footerStyles.fform} type="text" placeholder="ชื่อ-นามสกุล" name="ชื่อ-นามสกุล" /></li>
                        <li className={footerStyles.lipaddingsocial}><button className={footerStyles.fbuttom} type="submit">ส่ง</button></li>
                    </ul>
                </form>
                </Col>
                <Col md={3} xs={12} className={footerStyles.fp}>
                    <ul className={footerStyles.bulletsnoa}>
                        <li className={footerStyles.lipaddingsocial}><Icon icon={facebookIcon} height={15} /></li>
                        <li className={footerStyles.lipaddingsocial}><Icon icon={instagramFill} height={15} /></li>
                        <li className={footerStyles.lipaddingsocial}><Icon icon={bxlYoutube} height={23} /></li>
                        <li className={footerStyles.lipaddingsocial}><Icon icon={lineIcon} height={16} /> @Thaipolypack</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </div>
    )
}