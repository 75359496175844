import React from "react"
import { Navbar, Nav, Button, Container } from "react-bootstrap"
import * as headerStyles from "./header.module.css"
import { Icon } from '@iconify/react';
import arrowRight from '@iconify/icons-akar-icons/arrow-right';
import { StaticImage } from "gatsby-plugin-image"

export default function Header() {
  
  return (
      <Navbar expand="lg" sticky="top">
      <Container fluid="xl">
        <Navbar.Brand href="/"><StaticImage src="../images/Logo.png" alt="Thaipolypack" className={headerStyles.logo} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className={headerStyles.navbarfont}>
            <Nav className="mr-auto">
            </Nav>
            <Nav>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about/">About</Nav.Link>
            <Nav.Link href="/ourproduct/">Our Product</Nav.Link>
            <Nav.Link href="/quality/">Quality Controll</Nav.Link>
            </Nav>
            <Button className={headerStyles.buttomcolor} rel="noreferrer" href="/contact/">Contact US <Icon icon={arrowRight} /></Button>{' '}
        </Navbar.Collapse>
      </Container>
      </Navbar>
  );
}